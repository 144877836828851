/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

@import "~animate.css/animate.css";

/* Tailwind */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: 'exo';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/eudoxus-sans/variable/EudoxusSansGX.ttf');
  }

  :root[mode=ios] .font-exo,
:root[mode=md] .font-exo{
  // --ion-font-family:  'exo'!important;
  // font-family:  'exo' !important;
}

.yolo{
  --ion-item-background:transparent !important;
}

ion-avatar { 
  width: 100%;  
  height: 100%;  
}


.animation-target {
  animation: animation 600ms linear both;
  // animation-delay: .2s;
}

/* Generated with Bounce.js. Edit at https://goo.gl/SxH15Z */

@keyframes animation {
  0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.4% { transform: matrix3d(1.043, 0, 0, 0, 0, 1.115, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.3% { transform: matrix3d(1.046, 0, 0, 0, 0, 1.144, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { transform: matrix3d(1.046, 0, 0, 0, 0, 1.154, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.81% { transform: matrix3d(1.037, 0, 0, 0, 0, 1.183, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  8.61% { transform: matrix3d(1.02, 0, 0, 0, 0, 1.176, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { transform: matrix3d(1.013, 0, 0, 0, 0, 1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  10.21% { transform: matrix3d(1.006, 0, 0, 0, 0, 1.154, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  12.91% { transform: matrix3d(0.992, 0, 0, 0, 0, 1.103, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.61% { transform: matrix3d(0.991, 0, 0, 0, 0, 1.089, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { transform: matrix3d(0.991, 0, 0, 0, 0, 1.079, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.22% { transform: matrix3d(1, 0, 0, 0, 0, 1.021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.52% { transform: matrix3d(1.001, 0, 0, 0, 0, 1.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { transform: matrix3d(1.007, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  21.32% { transform: matrix3d(1.017, 0, 0, 0, 0, 0.958, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { transform: matrix3d(1.022, 0, 0, 0, 0, 0.932, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% { transform: matrix3d(1.022, 0, 0, 0, 0, 0.928, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  28.33% { transform: matrix3d(1.016, 0, 0, 0, 0, 0.929, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% { transform: matrix3d(1.014, 0, 0, 0, 0, 0.933, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { transform: matrix3d(1.011, 0, 0, 0, 0, 0.938, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { transform: matrix3d(0.994, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  36.74% { transform: matrix3d(0.991, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  39.44% { transform: matrix3d(0.987, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { transform: matrix3d(0.986, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  44.44% { transform: matrix3d(0.987, 0, 0, 0, 0, 1.021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { transform: matrix3d(0.996, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  59.86% { transform: matrix3d(1.003, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  61.66% { transform: matrix3d(1.003, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { transform: matrix3d(1.003, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  75.28% { transform: matrix3d(1.001, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  83.98% { transform: matrix3d(0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { transform: matrix3d(0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  90.69% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}



ion-content{

  --ion-background-color:#F5F6FA;
  --ion-background-color:#fff;
}

.bg-container-blue{
  background: #F5F6FA;
  background: #f1f3fb;
}

.bg-container-purple{
  background: #f9f1fb;
  background: #fdebfd;
}

.bg-container-offwhite{
  background: #fbfbfb;
  background: #fbfbfb;
}


.temp-blue{
  background: #23304E;
  border: 1px solid #101624 !important;
}

.temp-figma-blue{
  background: #022964;
  background: #344774;
}

.bg-comp-blue{
  background: #344774;
}

.temp-green{
  background: #23304E;
  background: #337436;
  
}

.temp-green-footer{
  --background: #337436;
}

.tail-card{
  // background: green!important;

  // border: 1px solid red !important;
  box-shadow: rgb(0 0 0 / 2%) 0px 4px 16px;
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.force-wide{
  width: 100%!important;
  height: 60px;
}

svg.icon-tone-down{
  opacity: .1!important;
}

ion-tab-button {
  --color-selected: #344774;
  // --color-selected: #60A5FA;
  --color-selected: #cc6ad0;
  
 }

 .scanner-ui { display: none; }
 .scanner-hide { visibility: visible; }
 
 body.qrscanner { background-color: transparent; }
 body.qrscanner .scanner-ui { display: block; }
 body.qrscanner .scanner-hide { visibility: hidden; }